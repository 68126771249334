import { render, staticRenderFns } from "./ArticleModal.vue?vue&type=template&id=7b802608&scoped=true"
import script from "./ArticleModal.vue?vue&type=script&lang=js"
export * from "./ArticleModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b802608",
  null
  
)

export default component.exports