<template>
  <div class="create-advert">
    <error :errors="advertsError" />
    <adverts-form />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import AdvertsForm from "../../../components/adverts/AdvertsForm";
  import Error from "../../../partials/widgets/Error";
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: { AdvertsForm, Error },
    computed: {
      ...mapGetters(['advertsError'])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئېلان باشقۇرۇش", route: "/adverts/list" },
        { title: "يېڭىدىن قوشۇش" }
      ]);
    }
  }
</script>
