<template>
  <div class="category-modal">
    <b-modal v-model="modalState" @hidden="hiddenModal" centered no-close-on-backdrop hide-footer title="ئاپتوردىن ئىزدەپ تاللاڭ">
      <b-form>
        <b-input-group>
          <b-form-input @keyup.enter="onSubmit" v-model="keyword" trim placeholder="ئاپتور نامىنى كىرگۈزۈپ ئىزدەڭ"></b-form-input>
          <b-form-input trim type="text" style="display: none;"></b-form-input>
          <b-input-group-append>
            <b-button @click="onSubmit" size="sm" variant="primary">ئىزدەش</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <b-table id="searchTable" :show-empty="true" :fields="tableFields" :items="tableItems">
        <template v-slot:empty="scope">
          <p class="text-center"> ھازىرچە مۇناسىۋەتلىك ئۇچۇر يوق ئىكەن . </p>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button-group>
            <b-button variant="primary" size="sm" @click="chooseThis(row.item)"  class="mr-1">
              مۇشۇنى تاللاي
            </b-button>
          </b-button-group>
        </template>
      </b-table>
      <b-pagination
              v-if="metaInfo"
              class="mt-2"
              v-model="metaInfo.current_page"
              :total-rows="metaInfo.total"
              :per-page="metaInfo.per_page"
              aria-controls="searchTable"
              @input="onChangePage"
      />
    </b-modal>
  </div>
</template>

<script>
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import authorMixin from "@m@/authorMixin";

  export default {
    name: "AuthorModal",
    props: {
      state: Boolean
    },
    mixins: [ authorMixin, sweetAlertMixin ],
    computed: {
      modalState: {
        get(){
          return this.state;
        },
        set(v){
          return v;
        }
      }
    },
    data(){
      return {
        keyword: '',
        tableFields: [
          { key: "title", label: "ئاپتور ئىسمى" },
          { key: "actions", label: "مەشغۇلات" }
        ],
        tableBusy: false,
        tableItems: [],
        metaInfo: null
      };
    },
    methods: {
      onSubmit(){
        if ( this.keyword ){
          this.searchAuthor();
        }else {
          return this.warning('ئىزدىمەكچى بولغان تۈر نامىنى كىرگۈزۈڭ!');
        }
      },
      searchAuthor(page = 1){
        this.setTableBusy();
        let title = this.keyword;
        this.getAuthorsForStore(title, page, false)
          .then(response=>{
            let {data, meta} = response.data;
            this.tableItems = data;
            this.metaInfo = meta;
            this.setTableBusy(false);
          })
          .then(()=>{
            this.setTableBusy(false);
          })
      },
      chooseThis(item){
        this.$emit('choose', item);
      },
      initModal(){
        this.$nextTick(()=>{
          this.keyword = '';
          this.tableItems = [];
          this.metaInfo = null;
        })
      },
      setTableBusy(busy = true){
        this.tableBusy = busy
      },
      onChangePage(page = 1){
        this.searchCategory(page);
      },
      hiddenModal(event){
        this.$emit('close');
      },
    }
  }
</script>

<style scoped>

</style>
