<template>
  <div class="adverts-form">
    <b-overlay :show="busy" rounded="sm">
    <portlet :title="portletTitle">
      <template v-slot:body>
        <b-form ref="adverts" @submit.stop.prevent="onSubmit" @reset="onReset">
          <b-form-row>
            <b-col sm="6" md="1">
              <b-form-group id="input-group-1" label="ئوچۇقمۇ ؟" label-for="is_show">
                <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row class="row">
            <b-col sm="6" md="3">
              <b-form-group id="input-group-8" label="ئېلان ماۋزوسى" label-for="title" description="نۆۋەتتىكى ئېلاننىڭ ماۋزوسىنى كىرگۈزۈڭ">
                <b-form-input id="title" v-model="formData.title" type="text" :state="validateState('title')" aria-describedby="title-live-feedback" placeholder="ئېلان ماۋزوسىنى كىرگۈزۈڭ" />
                <b-form-invalid-feedback id="title-live-feedback">
                  ئېلان ماۋزوسىنى كىرگۈزۈڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6" md="3">
              <b-form-group :state="validateState('ad_type')" aria-describedby="ad-type-live-feedback" id="input-group-2" label="ئېلان تۈرى" label-for="ad_type">
                <b-form-radio-group v-b-tooltip :title="adTypeToolTip" id="ad_type" v-model="formData.ad_type" :options="adType" buttons name="radios-btn-default" />
                <b-form-invalid-feedback id="ad-type-live-feedback">
                  ئېلان تۈرىنى تاللاڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6" md="3">
              <b-form-group :state="validateState('source')" aria-describedby="source-live-feedback" id="input-group-3" label="ئېلان مەنبەسى" label-for="source">
                <b-form-radio-group id="source" @change="onChangeSource" v-model="formData.source" :options="sourceType" buttons name="radios-btn-default"
                />
                <b-form-invalid-feedback id="source-live-feedback">
                  ئېلان مەنبەسىنى تاللاڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="formData.source">
            <b-col sm="6" md="6" v-if="formData.source === 'external_link'">
              <b-form-group id="input-group-4" label="ئۇلانما ئادېرسى" label-for="chain" description="نۆۋەتتىكى نۆۋەتتىكى ئېلاننىڭ سىرتقى ئۇلانمىسىنى كىرگۈزۈڭ">
                <b-form-input :state="validateState('chain')" aria-describedby="chain-live-feedback" id="chain" v-model="formData.chain" style="direction: ltr" placeholder="ئۇلانما ئادېرسىنى كىرگۈزۈڭ" />
                <b-form-invalid-feedback id="chain-live-feedback">
                  ئېلان ئۇلانما ئادېرسىنى كىرگۈزۈڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <template v-if="formData.source === 'miniprogram'">
              <b-col sm="6" md="3">
                <b-form-group id="input-group-10" label="ئەپچاق ئادېرسى" label-for="chain" description="ئەپچاقنىڭ ئۇلانمىسىنى كىرگۈزۈڭ">
                  <b-form-input :state="validateState('chain')" aria-describedby="chain-live-feedback" id="chain" v-model="formData.chain" style="direction: ltr" placeholder="ئۇلانما ئادېرسىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="chain-live-feedback">
                    ئەپچاق ئۇلانما ئادېرسىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-11" label="ئەپچاق Appid" label-for="chain" description="ئەپچاقنىڭ Appid سىنى كىرگۈزۈڭ">
                  <b-form-input :state="validateState('app_id')" aria-describedby="appid-live-feedback" id="chain" v-model="formData.app_id" style="direction: ltr" placeholder="ئەپچاقنىڭ Appid سىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="appid-live-feedback">
                    ئەپچاق Appid سىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </template>
            <b-col v-if="formData.source === 'article'" sm="6" md="3">
              <b-form-group label="ئەسەر تاللاڭ" >
                <b-input-group>
                  <b-form-input :state="validateState('chain')" aria-describedby="target-live-feedback" v-model="targetTitle" placeholder="سول تەرەپتىكى كونۇپكىنى بېسىپ ئەسەر تاللاڭ" readonly />
                  <b-input-group-append>
                    <b-button @click="showArticleModal" v-b-tooltip.hover title="ئەسەر تاللاش" trim variant="success">
                      <i class="fa fa-layer-group text-white p-0"></i>
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback id="target-live-feedback">
                    قوشماقچى بولغان ئەسەرنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col v-if="formData.source === 'category'" sm="6" md="3">
              <b-form-group label="تۈر تاللاڭ" >
                <b-input-group>
                  <b-form-input :state="validateState('chain')" aria-describedby="target-live-feedback" v-model="targetTitle" placeholder="سول تەرەپتىكى كونۇپكىنى بېسىپ تۈر تاللاڭ" readonly />
                  <b-input-group-append>
                    <b-button @click="showCategoryModal" v-b-tooltip.hover title="تۈر تاللاش" trim variant="success">
                      <i class="fa fa-layer-group text-white p-0"></i>
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback id="target-live-feedback">
                    قوشماقچى تۈر ئەسەرنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col v-if="formData.source === 'author'" sm="6" md="3">
              <b-form-group label="ئاپتور تاللاڭ" >
                <b-input-group>
                  <b-form-input :state="validateState('chain')" aria-describedby="target-live-feedback" v-model="targetTitle" placeholder="سول تەرەپتىكى كونۇپكىنى بېسىپ ئاپتور تاللاڭ" readonly />
                  <b-input-group-append>
                    <b-button @click="showAuthorModal" v-b-tooltip.hover title="ئاپتور تاللاش" trim variant="success">
                      <i class="fa fa-layer-group text-white p-0"></i>
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback id="target-live-feedback">
                    قوشماقچى بولغان ئاپتورنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col sm="6" md="3">
              <b-form-group id="input-group-9" label="ئېلان تەرتىبى" label-for="sort" description="نۆۋەتتىكى ئېلاننىڭ تەرتىبىنى كىرگۈزۈڭ">
                <b-form-input style="width: 100%;" id="sort" v-model="formData.sort" type="number" placeholder="ئېلان تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
              </b-form-group>
            </b-col>
            <b-col sm="6" md="3">
              <b-form-group id="input-group-6" :state="validateState('start_time')" aria-describedby="start_time-live-feedback" label="باشلىنىش ۋاقتىنى تاللاڭ" label-for="start_time">
                <datetime placeholder="بۇ يەرنى بېسىپ تاللاڭ" id="start_time" v-model="formData.start_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
                <b-form-invalid-feedback id="start_time-live-feedback">
                  ئېلان باشلىنىش ۋاقتىنى تاللاڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6" md="3">
              <b-form-group id="input-group-7" :state="validateState('end_time')" aria-describedby="end_time-live-feedback" label="ئاياقلىشىش ۋاقتىنى تاللاڭ" label-for="end_time">
                <datetime placeholder="بۇ يەرنى بېسىپ تاللاڭ" id="end_time" v-model="formData.end_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
                <b-form-invalid-feedback id="start_time-live-feedback">
                  ئېلان ئاياقلىشىش ۋاقتىنى تاللاڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="6" md="3">
              <b-form-group id="input-group-cover_image" :state="validateState('cover_image')" aria-describedby="start_time-live-feedback" label="ئېلان رەسىمى" label-for="cover_image">
                <image-uploader :check-size="false" :width="750" :height="350" @success="onUploadSuccess" :img-url="oldImage" :upload-form="{strategy: 'adverts'}" id="cover_image" />
                <b-form-invalid-feedback id="cover_image-live-feedback">
                  ئېلان رەسىمىنى تاللاڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-button id="submit" type="submit" variant="primary">
            ساقلاش
          </b-button>
          <b-button class="ml-2" type="reset" variant="danger">
            بىكار قىلىش
          </b-button>
        </b-form>
      </template>
    </portlet>
    </b-overlay>
    <article-modal :state="articleModalState" @choose="onChooseArticle" @close="closeArticleModal" />
    <category-modal :state="categoryModalState" @choose="onChooseCategory" @close="closeCategoryModal" />
    <author-modal :state="authorModalState" @choose="onChooseAuthor" @close="closeAuthorModal" />
  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import ImageUploader from "../../partials/layout/_ImageUploader";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import ApiService from "../../../common/api.service";
  import { SET_ADVERTS_ERROR } from "../../../store/types";
  import { FIND_ADVERTS } from "../../../store/types";
  import handleReject from "../../../common/handleReject";
  import sweetAlertMixin from "../../../mixins/common/sweetAlert.mixin";
  import ArticleModal from "../choseModal/ArticleModal";
  import CategoryModal from "../choseModal/CategoryModal";
  import AuthorModal from "../choseModal/AuthorModal";
  export default {
    name: "AdvertsForm",
    props: {
      action: {
        type: String,
        default: "create"
      }
    },
    mixins: [validationMixin, sweetAlertMixin],
    validations: {
      formData: {
        ad_type:    { required },
        title:      { required },
        cover_image:{ required },
        source:     { required },
        chain:      { required },
        start_time: { required },
        end_time:   { required },
        app_id: { required: requiredIf(formData=>{
          return formData.source === 'miniprogram';
        })}
      }
    },
    components: { Portlet, ImageUploader, datetime: Datetime, ArticleModal, CategoryModal, AuthorModal },
    computed: {
      portletTitle(){
        let create = "يېڭىدىن ئېلان قوشۇش";
        let edit = "ئېلان ئۇچۇرىنى تەھرىرلەش";
        return this.action === "create" ? create : edit;
      },
      oldImage(){
        return this.action === 'edit' && this.formData.cover_image || '';
      },
    },
    created() {
      if ( this.action === 'edit' )
        this.getCurrentAdvert()
    },
    data(){
      return {
        targetTitle: '',
        articleModalState: false,
        categoryModalState: false,
        authorModalState: false,
        busy: false,
        formData: {
          is_show: true,
          ad_type: null,
          source: null,
          title: "",
          sort: null,
          cover_image: "",
          chain: null,
          start_time: null,
          end_time: null
        },
        adType: [
          {text: "پىلاكات ئېلانى", value: "banner"},
          {text: "باشقا ئېلانلار", value: "other"},
        ],
        sourceType: [
          {text: "ئەسەر", value: "article", disabled: false},
          {text: "تۈر", value: "category", disabled: false},
          {text: "ئاپتور", value: "author", disabled: false},
          {text: "ئەپچاق", value: "miniprogram", disabled: false},
          {text: "سىرتقى ئۇلانما", value: "external_link", disabled: false},
        ],
        adTypeToolTip: `پىلاكات ئېلانى بولسا پەقەت باش بەت ئۈستىدىكى ئايلانما رەسىم ئورنىدىلا كۆرۈنىدۇ`
      };
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
          return;
        }
        if ( this.action === 'create' )
          this.createAdvert();
        else if ( this.action === 'edit' )
          this.updateAdvert();
      },
      createAdvert(){
        this.busy = true;
        ApiService.setHeader();
        ApiService.post(`/manager/advert`, this.formData)
          .then(response=>{
            this.busy = false;
            this.$store.commit(SET_ADVERTS_ERROR, null);
            return this.$router.push({name: 'adverts.list'});
          })
          .catch((response)=>{
            this.busy = false;
            handleReject(this.$store, response, SET_ADVERTS_ERROR);
          })
      },
      updateAdvert(){
        this.busy = true;
        ApiService.setHeader();
        ApiService.put(`/manager/advert/${this.formData.id}`, this.formData)
          .then(response=>{
            this.busy = false;
            this.$store.commit(SET_ADVERTS_ERROR, null);
            this.success('تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!');
          })
          .catch(({ response })=>{
            this.busy = false;
            handleReject(this.$store, response, SET_ADVERTS_ERROR);
          })
      },
      onReset(){
        return this.$router.replace({name: 'adverts.list'});
      },
      onUploadSuccess(response){
        this.formData.cover_image =response.path;
      },
      getCurrentAdvert(){
        let id = this.$route.params.id;
        this.busy = true;
        this.$store.dispatch(FIND_ADVERTS, id)
          .then(response=>{
            let { data } = response;
            this.formData = data;
            let { chain_related } = data;
            chain_related = chain_related ? chain_related : {};
            this.targetTitle = chain_related.title;
            this.busy = false;
          })
          .catch(response=>{
            this.busy = false;
          })
      },
      onChangeSource(e){
        console.log(e);
        this.formData.chain = null;
        this.targetTitle = null;
      },
      toggleArticleModal(){
        this.articleModalState = ! this.articleModalState;
      },
      showArticleModal(){
        this.toggleArticleModal();
      },
      closeArticleModal(){
        this.articleModalState = false;
      },
      onChooseArticle(e){
        this.targetTitle = e.title;
        this.formData.chain = e.id;
        this.$nextTick(()=>{
          this.toggleArticleModal();
        })
      },

      toggleCategoryModal(){
        this.categoryModalState = ! this.categoryModalState;
      },
      showCategoryModal(){
        this.toggleCategoryModal();
      },
      closeCategoryModal(){
        this.categoryModalState = false;
      },
      onChooseCategory(e){
        this.targetTitle = e.title;
        this.formData.chain = e.id;
        this.$nextTick(()=>{
          this.toggleCategoryModal();
        })
      },

      toggleAuthorModal(){
        this.authorModalState = ! this.authorModalState;
      },
      showAuthorModal(){
        this.toggleAuthorModal();
      },
      closeAuthorModal(){
        this.authorModalState = false;
      },
      onChooseAuthor(e){
        this.targetTitle = e.title;
        this.formData.chain = e.id;
        this.$nextTick(()=>{
          this.toggleAuthorModal();
        })
      },
      ImgColor() {
      let domImg = document.querySelector('img'); // 获取图片dom节点
      let colorthief = new ColorThief();
      domImg.addEventListener('load', () => { // 图片加载
        console.log('主色调', this.rgbaToHex(colorthief.getColor(domImg)));//图片主色调，第二个参数可选(1~10)
      })
    },
    rgbaToHex(rgba) { // rgba转16进制
      let hex = '#';
      for (const i of rgba) {
        hex += i.toString(16).padStart(2, '0');
      }
      return hex;
    },
    }
  }
</script>

<style scoped>
  .input-group i{
    line-height: 1;
  }
</style>
