import {
  CREATE_CATEGORY,
  FIND_CATEGORY, GET_CATEGORIES_FOR_ALL, GET_CATEGORIES_LIST,
  SET_CATEGORY_ERROR, SWITCH_CATEGORY_STATE,
  UPDATE_CATEGORY
} from "../store/types";
import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";

export default {
  data(){
    return {
      categoriesForStore: [],
      categoriesForStoreMeta: {}
    }
  },
  methods: {
    deleteCategory(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/manager/categories/${id}`)
        .then(response=>{
          this.hideBusy();
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_CATEGORY_ERROR);
          reject(response);
        })
      })
    },
    createCategory(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(CREATE_CATEGORY, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    updateCategory(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(UPDATE_CATEGORY, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    findCategory(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(FIND_CATEGORY, id)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getCategories(options = {page: 1, title: ''}, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_CATEGORIES_LIST, options)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    switchCategoryState(id, params){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(SWITCH_CATEGORY_STATE, {id, params})
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getCategoriesForStore(title = false, page = 1, busy = false){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post('/manager/categories/list-for-store', {title, page})
        .then(response=>{
          let { data, meta } = response.data;
          this.categoriesForStore = data;
          this.categoriesForStoreMeta = meta;
          this.$store.commit(SET_CATEGORY_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_CATEGORY_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
    getCategoriesForAll(busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_CATEGORIES_FOR_ALL)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    }
  }
}
